<template>
  <Transaction />
</template>

<script>
import Transaction from '@/components/Transaction.vue'

export default {
  components: {
    Transaction,
  },
}
</script>
